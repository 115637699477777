<template>
    <div class="content">
        <div class="top_bg" style="background-image: url('img/qixi/top_bg.png');">
            <div class="active_title" style="background-image: url('img/qixi/active_title.png');"> </div>
            <!-- <div class="active_time" style="background-image: url('img/qixi/active_time_bg.png');"> </div> -->
            <div @click="goBack" class="go_back" style="background-image: url('img/qixi/go_back.png');"></div>
        </div>
        <div class="rule_content" style="background-image: url('img/qixi/rule_bg.png');">
            <div class="rule_title" style="background-image: url('img/qixi/rule_title.png');"></div>
            <div class="rule_detail" style="background-image: url('img/qixi/rule_detail.png');"></div>
        </div>
    </div>
</template>
<script>
import "@/assets/css/qixiActive.css"
export default {
    data() {
        return {

        }
    },
    created() {

    },
    methods: {
        goBack() {
            this.$router.go(-1)
        }
    }
}
</script>